<template>
    <div>
        <div class="container-fluid ">
            <div class="container my-5 py-5">
                <div class="d-flex flex-column align-items-center">
                    <img class="col-8 col-lg-4 mb-5" :src="vector.url" alt="" />
                    <h1 class="col-12 col-lg-8 fw-bold text-center text-darkblue mt-4 mb-5">
                        THAILAND PERSONAL INCOME TAX FILING SERVICE FOR FOREIGNERS
                    </h1>
                    <div class="col-12 col-lg-8">
                        <h5 class="mb-3">Our personal income tax filing service includes :</h5>
                        <ul>
                            <li v-for="(service, index) in services" :key="index" class="py-1">
                                {{ service }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
li {
    list-style: "—   ";
}
</style>
<script>
export default {
    data() {
        return {
            vector: {
                url: "https://firebasestorage.googleapis.com/v0/b/jnpaccounting-5fe5f.appspot.com/o/imgs%2Fvector%2Fdiscount.svg?alt=media&token=bb59c77b-df80-478a-a906-985786f1b5da"
            },
            services: [
                "Calculation of tax equalization for Expat",
                "Preparation of PIT return filing",
                "Advisory services on the PIT implications",
                "Meeting with Revenue Department on Tax Audit / Tax refund Audit"
            ]
        }
    }
}
</script>